/*
*
* Background Images Custom
* --------------------------------------------------
*/

.home-header {
  background: url(../../assets/images/daniel-enzensperger/2021_Enzensperger_Header.jpg) 40% top no-repeat #0A0A0A;
}

.ueber-mich-header {
  background: url(../../assets/images/daniel-enzensperger/enzensperger_vor-wand.jpg) no-repeat right #0A0A0A;
}

.wofuer-ich-stehe-header {
  background: url(../../assets/images/daniel-enzensperger/enzensperger_vor-wand.jpg) no-repeat right #0A0A0A;
}

.gemeinsam-erreicht-header {
  background: url(../../assets/images/daniel-enzensperger/enzensperger_vor-wand.jpg) no-repeat right #0A0A0A;
}

.ziele-header {
  background: url(../../assets/images/daniel-enzensperger/enzensperger_vor-wand.jpg) no-repeat right #0A0A0A;
}

.kontakt-header {
  background: url(../../assets/images/daniel-enzensperger/enzensperger_vor-wand.jpg) no-repeat right #0A0A0A;
}

.home-header {
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }


  @media (max-width: 767px) {
    margin-top: 60px;
  }

}


.volunteer-section {
  background: url(../../assets/images/daniel-enzensperger/enzensperger_vor-wand.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
  color: #fff;
}

.ziele-img-1 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-schueler.jpg) 62% 30% no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-2 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-familien.jpg) 0 30% no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-3 {
  background: url(../../assets/images/daniel-enzensperger/klimaschutz.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-4 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-digitalisierung.jpg) center top no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-5 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-sport.jpg) 75% 35% no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-6 {
  background: url(../../assets/images/daniel-enzensperger/infrastruktur.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}


.ziele-img-7 {
  background: url(../../assets/images/daniel-enzensperger/feuerwehr.jpg) 30% 70% no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-8 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-planung.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-9 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-landwirtschaft.jpg) 100% 25% no-repeat #5E5E5E;
  background-size: cover;
}

.ziele-img-10 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-rathaus.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}


// -----------------------
//Gemeinsam erreicht Bilder
//------------------------


.gemeinsam-erreicht-img-1 {
  background: url(../../assets/images/daniel-enzensperger/parkschule-gross.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-2 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-familien-03-gross.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-3 {
  background: url(../../assets/images/daniel-enzensperger/umweltschutz.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-4 {
  background: url(../../assets/images/daniel-enzensperger/digitalisierung.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-5 {
  background: url(../../assets/images/daniel-enzensperger/festhalle-gross.jpg) 0 center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-6 {
  background: url(../../assets/images/daniel-enzensperger/strassenbau.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}


.gemeinsam-erreicht-img-7 {
  background: url(../../assets/images/daniel-enzensperger/sicherheit.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-8 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-planung.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-9 {
  background: url(../../assets/images/daniel-enzensperger/wirtschaft.jpg) 100% center no-repeat #5E5E5E;
  background-size: cover;
}

.gemeinsam-erreicht-img-10 {
  background: url(../../assets/images/daniel-enzensperger/enzensperger-rathaus.jpg) center center no-repeat #5E5E5E;
  background-size: cover;
}


.ueber-mich-header,
.wofuer-ich-stehe-header,
.gemeinsam-erreicht-header,
.ziele-header,
.kontakt-header {
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}


.donation-section > .container-fluid > .row {
  display: flex;

  .donation-thumb {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .donation-section > .container-fluid > .row {
    .donation-thumb {
      min-height: 600px;
    }
  }
}

.tt-fullHeight {
  height: 100vh;
  min-height: 400px;
}


.tt-halfHeight {
  height: 50vh;
  min-height: 400px;

  .intro {
    top: 65%;

  }
}


.donation-section {

  .donation-intro,
  .donation-thumb {
    min-height: 150px;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-custom {
    padding-bottom: 0;
  }
}

.btn-padding-top {
  margin-top: 50px;
}

.map-container {

  position: relative;
  padding-bottom: 58%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}


.footer-nav {
  margin: 50px 0;
  display: flex;
  justify-content: flex-end;
  list-style: none;
}


.footer-section > .container .navbar-brand,
.footer-section > .container-fluid .navbar-brand {
  margin-left: -15px;
}

/*
*
* Cards Custom
* --------------------------------------------------
*/

.card {
  margin-bottom: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.card-custom {
  padding: 22px 0 20px;
}

.card-custom + .card-custom {
  border-top: 1px solid #ef4836;
  margin-top: 0;
}

.card-custom .card-custom-title > a {
  display: block;
  position: relative;
  padding-right: 40px;
  color: inherit;
  background: transparent;
  transition: .3s all ease;
}

.card-custom .card-custom-title > a:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  font: 400 24px/24px "Linearicons";
  color: #ef4836;
  transform: translateY(-50%) rotate(0deg);
  transition: .35s all ease-in-out;
}

.card-custom .card-custom-title > a span {
  padding-left: 8px;
  color: inherit;
  font-size: 14px;
}

.card-custom .card-custom-title > a.collapsed {
  background: inherit;
}

.card-custom .card-custom-title > a.collapsed:before {
  content: '\ec36';
  transform: translateY(-50%) rotate(180deg);
}

.card-custom .card-custom-title > a:hover {
  text-decoration: none;
  background: transparent;
}

.card-custom .card-custom-collapse {
  background: transparent;
}

.card-custom .card-custom-collapse .card-custom-body {
  padding: 15px 25px 30px 0;
}

@media (min-width: 768px) {
  .card-custom .card-custom-collapse .card-custom-body {
    padding: 30px 30px 22px 0;
  }
}

h3 + .card-custom-group-wrap {
  margin-top: 30px;
}

.card-classic .card-custom-title > a {
  padding-left: 35px;
  padding-right: 0;
}

.card-classic .card-custom-title > a:before {
  content: "\e93c";
  top: 47%;
  left: 0;
  right: auto;
  transform: translateY(-50%) rotate(90deg);
  transition: .35s all ease-in-out;
}

.card-classic .card-custom-title > a.collapsed:before {
  content: '\e93c';
  transform: translateY(-50%) rotate(0deg);
}

.card-classic .card-custom-title > a:hover {
  text-decoration: none;
  background: transparent;
}

.vide {
  width: 100%;
}

.vide .container {
  position: relative;
  z-index: 2;
}



h1, h2, .intro p {
  font-family: Lora, "Times New Roman", Times, serif;
  text-transform: uppercase;
}

.intro {
  p {
    color: white;
    font-size: 26px;
    line-height: 1.5;
  }
}

.italic {
  font-style: italic;
}

.logo-name {
  width: 240px;
  margin-top: 8px;
  margin-left: 60px;
}

.color-section {
  background-color: #f4f4f4;
}

.magenta {
  color: magenta;
}

.hr-trenner {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ef4035;
  max-width: 50px;
  position: relative;

  &:after {
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ef4035;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: -4px;
    left: 50%;
    margin-left: -4px;
  }
}

.quote-container {
  margin: 30px 0 5px 0;
  border-top: 1px solid #ef4035;
  padding: 10px 0;

  .quote-container__inner {
    position: relative;
    background-color: #f4f4f4;
    padding: 20px;
    min-height: 150px;


    .quote-svg-container {
      position: relative;
    }


    .quote-svg {
      width: 25px;
      height: 25px;
      position: absolute;
      padding: 5px;
      background-color: #ef4035;
      transform: rotate(45deg);

      &.quote-2 {
        bottom: -23px;
        left: calc(50% - 12.5px);
      }

      &.quote-1 {
        top: -23px;
        right: calc(50% - 12.5px);
      }

    }
  }


  p, h4 {
    font-family: Lora, "Times New Roman", Times, serif;
    font-size: 16px;
    text-transform: none;
    font-weight: 400;
    line-height: 1.5;
  }

  p {
    bottom: 20px;
    right: 20px;
    position: absolute;
    margin-bottom: 0;
    text-align: end;
  }
}

@media (min-width: 1200px) {

  .nav-container {
    width: 85%;
  }
}

.spotlight-section {
  h2 {
    padding: 0 10px;
  }
}


@media (max-width: 991px) {

  .custom-flex-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .custom-flex {
    display: flex;
    flex-direction: column;
  }

  .custom-flex .col-md-6 {
    width: 100%;
  }

  .custom-flex .col-md-6 .donation-thumb,
  .custom-flex-reverse .col-md-6 .donation-thumb {
    min-height: 320px;
  }


  .custom-flex .col-md-6 .donation-intro,
  .custom-flex-reverse .col-md-6 .donation-intro {
    padding: 30px 30px 60px 30px;

  }

}

@media (max-width: 500px) {

  .custom-list {
    padding-left: 12px;
  }

}

.mission-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: -20px;
}

@media (max-width: 767px) {
  .mission-section img {
    margin-bottom: 10px;
  }

  .custom-flex-2-reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .mission-promo {
    margin-bottom: 10px;
  }
}


.mission-section .mb-50 {
  margin-bottom: 100px;
}

@media (max-width: 600px) {


  .mission-section .mb-50 {
    margin-bottom: 50px;
  }
}


// ueber mich Akkordion for mobil

.tab-content-mobil {
  display: none !important;
}

@media (max-width: 767px) {
  .abilities-tab {
    display: none !important;
  }

  .tab-content-mobil {
    display: block !important;

    .img-responsive {
      margin-bottom: 12px;
    }

    .row {
      margin-bottom: 40px;

      h3 {
        padding: 10px 15px;
        background-color: #ef4035;
        color: #fff;
        border: 1px solid #ef4035;
      }
    }
  }
}

.legal-section {

  h2, h3, h4 {
    margin-bottom: 5px;
  }

  h2 {
    margin-top: 50px;
  }

  h3 {
    margin-top: 40px;
  }

  h4 {
    margin-top: 20px;
  }
}

#vereine {
  .col-md-10 {
    margin: 0 auto;
  }

  p {
    text-align: center;
  }

  .verein-img {

    margin: 32px auto 0 auto;
    max-width: 720px;
  }
}


.vita-section {

  .vita-row {

    .vita-img-link {
      img {
        border: 1px solid #ef4035;
        padding: 10px;
      }

    }

    .btn {
      margin-top: 10px;
    }

  }
}


.wofuer-ich-stehe-home-row {
  display: flex;
  justify-content: space-around;
}

.wofuer-ich-stehe-home-row-2 {
  display: flex;
  justify-content: space-evenly;

}

.wofuer-ich-stehe-home-row,
.wofuer-ich-stehe-home-row-2 {
  margin-bottom: 50px;

  h3 {
    max-width: 330px;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    h3 {
      max-width: 800px;
      margin-bottom: 50px;
    }
  }
}

// Navigation
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-container {
    width: auto;
  }
  .navbar .nav > li {
    padding: 0 8px;
  }

}


@media only screen and (min-width: 768px) and (max-width: 806px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 11px;
  }
}


//Termine

@media only screen and (min-width: 992px) {
  #termine {
    .row {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: 992px) {
  #termine {
    .flex-row {
      display: flex;

      .col-md-4 {
        display: flex;

        article.blog-post-wrapper {
          flex: 1;
          display: flex;

          .blog-content {
            flex: 1;
          }
        }
      }

    }
  }

}

#termine {
  .entry-title {
    text-transform: none;
  }
}



@media (max-width: 1000px) {
  .about-section {
    padding-top: 80px;
  }

  .mission-section {
    padding: 80px 0 40px 0;
  }

  .spotlight-section {
    padding: 80px 0;
  }

  .campaign-section {
    padding: 80px 0 70px;
  }

  .footer-section {
    padding-top: 60px;
  }

  .press-release-section {
    padding: 80px 0 70px;
  }

  .section-intro {
    margin-bottom: 60px;
  }
}

@media (max-width: 800px) {
  .about-section {
    padding-top: 60px;
  }

  .mission-section {
    padding: 60px 0 35px 0;
  }

  .spotlight-section {
    padding: 60px 0;
  }

  .campaign-section {
    padding: 60px 0 50px;
  }

  .footer-section {
    padding-top: 50px;
  }

  .press-release-section {
    padding: 60px 0 50px;
  }

  .section-intro {
    margin-bottom: 50px;
  }
}

@media (max-width: 600px) {
  .about-section {
    padding-top: 45px;
  }

  .mission-section {
    padding: 45px 0 30px 0;
  }

  .spotlight-section {
    padding: 45px 0;
  }

  .campaign-section {
    padding: 45px 0 40px;
  }

  .footer-section {
    padding-top: 40px;
  }

  .press-release-section {
    padding: 45px 0 40px;
  }

  .section-intro {
    margin-bottom: 40px;
  }
}








